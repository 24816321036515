import { GetAppOutlined } from "@mui/icons-material";
import React from "react";
import { LoadingIndicator, Buttons, PseudoLink2 } from "../../toolympus/components/primitives";
import { DownloadFileData } from "../../toolympus/hooks/useDownloadFile";

interface GetFileControlProps {
  label: string;
  download: () => void;
  isDownloading: boolean;
}

export const GetFileControl = (props: GetFileControlProps) => {
  return props.isDownloading
    ? <LoadingIndicator sizeVariant="s" />
    : (
      <Buttons gap="small" onClick={() => !props.isDownloading && props.download()}>
        <GetAppOutlined color="primary" fontSize="inherit" />
        <PseudoLink2>
          {props.label}
        </PseudoLink2>
      </Buttons>
    );
}


/**
 * Displays buttons to download a bunch of files, which come in the fileIds prop, which must be a string with <fileid> or <fileid;filename> on every line
 * @param props 
 * @returns 
 */
export const GetMultifileDisplay = (props: { fileIds: string | undefined, apiPathPrefix: string, downloader: DownloadFileData }) => {
  const fileIds = (props.fileIds || "").split("\n").map(s => s.trim()).filter(s => !!s)
    .map(fileIdAndName => {
      const fidEnds = fileIdAndName.indexOf(";");
      return fidEnds >= 0
        ? [fileIdAndName.substring(0, fidEnds), fileIdAndName.substring(fidEnds+1)]
        : [fileIdAndName, ""];
    });
  
  return (
    <Buttons vertical>
      {fileIds
        .map(([fid,filename],idx) => (
          <GetFileControl
            key={fid}
            label={filename || `файл ${idx+1}`}
            isDownloading={props.downloader.isLoading}
            download={() => props.downloader.download({ apiPath: `${props.apiPathPrefix}/${fid}`, filename })}
            />
        ))}
    </Buttons>
  );
}
