import React from 'react';
import { Form, FormGrid } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { EditorBoardSettingsSchema, useEditorBoardConfigurationEdit } from './useEditorBoardConfiguration';

interface Props {
  
}

export const EditorBoardConfiguration = (props: Props) => {
  const data = useEditorBoardConfigurationEdit();

  return (
    <Form
      title="Редакционный совет">
      
      <FormGrid columns="300px 200px 1fr" forceEvenColumns>
        <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            schema={data.editorBoardSchema}
            fields={data.editorBoardFields}
            data={data.board}
            onChange={(o,c) => data.updateBoard(c)}
            />
        </FormGrid>

        <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            schema={EditorBoardSettingsSchema}
            fields={[
              ["editor_rating_max"]
            ]}
            data={data.boardSettings}
            onChange={(o,c) => data.update(c)}
            />
        </FormGrid>
      </FormGrid>
    </Form>
  );
}
