import React from 'react';
import { CommentsForm, useComments, useTableComments as useTableCommentsTl } from "../../toolympus/components/Comments";
import { useUserNames } from "./Users"

const alwaysAllowed = () => true;

const ApiPath = "/api/comment";

export const useTableComments = (entity: string) => {
  const { getUsername } = useUserNames();
  const comments = useTableCommentsTl(ApiPath, entity, { getUserName: getUsername, isRemoveAllowed: alwaysAllowed });

  return comments;
}

interface Props {
  entity: string;
  recordId: string;
}

export const Comments = ({ entity, recordId }: Props) => {
  const { getUsername } = useUserNames();

  const comments = useComments(ApiPath, entity, recordId, { getUserName: getUsername, isRemoveAllowed: alwaysAllowed });
  return (
      <CommentsForm data={comments} />
  );
}
