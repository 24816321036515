import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { OccupyFreeSpace } from '../../toolympus/components/primitives';


export const RatingWrapper = styled.div<{ pct: number }>`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  user-select: none;

  & .circle {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    position: relative;

    width: 28px;
    height: 28px;
    

    &:before {
      background: #c43337;
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 28px;
      opacity: ${props => 0.3 + props.pct * 0.7};
    }

    & .value {
      font-size: 14px;
      transform: scale(${props => 1 + props.pct * 0.2});
      transform-origin: center;
      display: block;
      line-height: 1;

    }
    
  }

`;


interface Props {
  value: number;
  update?: (v: number) => void;
  onClick?: () => void;
  maxRating: number;
  label?: ReactNode;
  digits?: number;
  straighten?: boolean;
}

export const EditorRating = (props: Props) => {
  return (
    <RatingWrapper
      onClick={props.update
        ? () => props.update && props.update((props.value || 0) >= props.maxRating ? 0 : (props.value || 0) + 1)
        : props.onClick}
      pct={(props.value || 0) / (props.maxRating || 10.0)}>
      {props.label}
      <OccupyFreeSpace />
      <div className="circle">
        <Typography component="span" className="value">{`${(props.value || 0).toFixed(props.digits === undefined ? 1 : props.digits)}`}</Typography>
      </div>
    </RatingWrapper>
  );
}
