import React, { useMemo } from 'react';
import { useApplicationsList } from './useApplicationsList';
import { ButtonOptionPicker, Buttons, Form, OccupyFreeSpace, PseudoLink2, SearchField, useDialogState } from '../../toolympus/components/primitives';
import { useOpenByIdFromUrl } from '../../toolympus/hooks/useOpenByIdFromUrl';
import { ApplicationRecord } from './typings';
import { ActionTriggerEditButton } from '../../toolympus/components/Actions';
import { Button, Typography } from '@mui/material';
import { MaybeFieldDefinition, TableForFields } from '../../toolympus/components/schemed';
import { ApplicationsConfigurationDialog } from './ApplicationsConfigurationDialog';
import { ApplicationReviewDialog } from './ApplicationReviewDialog';
import { useDownloadFile } from '../../toolympus/hooks/useDownloadFile';
import { GetMultifileDisplay } from './ApplicationsFiles';
import { FormattedMessage } from 'react-intl';
import { useEditorBoardConfiguration } from '../Administration/useEditorBoardConfiguration';
import { EditorRating } from '../Administration/EditorRating';

interface Props {
  
}


const RatingFields = [
  "editor_rating_avg",
  ...[1,2,3,4,5,6].map(i => `editor_rating_${i}`),
]


export const ApplicationsList = (props: Props) => {
  const data = useApplicationsList();
  const configDialog = useDialogState();

  const editorBoard = useEditorBoardConfiguration();

  const [fields, fileFields] = useMemo(() => {
    const fields: MaybeFieldDefinition[] = [
      ["email"],
      ["display_name"],
      ["approval_status"],
      ["editor_rating_avg"],
      ...editorBoard.editorRatingFieldsDefinitionSimple,
      ...data.config.data.fields.filter(f => !["text", "text_multiline", "email", "boolean"].includes(f.fieldtype)).map(f => [f._id] as MaybeFieldDefinition),
      ["created_at", { utcToLocal: true }],
    ];

    const fileFields = data.config.data.fields.filter(f => f.fieldtype === "file_multi").map(f => f._id);

    return [fields as MaybeFieldDefinition[], fileFields];
  }, [data.config.data, editorBoard.editorRatingFieldsDefinitionSimple]);

  const downloadFile = useDownloadFile("");

  useOpenByIdFromUrl<ApplicationRecord>("id", "_id", data.data, x => data.review.startEditing(x), data.review.item?._id);
  
  return (<>
    <Form
      title="Заявки"
      headerItems={<>
        <Typography variant="caption" color="textSecondary" style={{ alignSelf: "flex-end" }}>
          <FormattedMessage id="forms.page.submissions_total" values={{ total: data.count.data.total_records }} />
        </Typography>
        <OccupyFreeSpace />


        <ButtonOptionPicker
          options={[
            ["Все","all"],
            ["???","unknown"],
            ["Исключенные", "excluded"],
            ["В ближайший", "to_next_issue"],
            ["В следующий", "to_nextnext_issue"],
            ["В номере", "released"],
          ]}
          selected={data.approvalStatusFilter.value}
          setSelected={v => data.approvalStatusFilter.update(v)}
          />

        <SearchField
          filter={data.filter.filter}
          setFilter={data.filter.setFilter}
          noButton
          />
        

        
        <ActionTriggerEditButton
          apiPath="/api/actions/trigger"
          triggers={[
            "plschdka.applications.applications/received",
          ]}
          />
        <Button size="small" color="primary" onClick={() => configDialog.open()}>конфигурация</Button>
      </>}>
      
      <TableForFields
        data={data.data}
        schema={data.schema}
        fields={fields}
        sorting={data.sorting}
        fieldElement={f => {
          console.log("PFS", f);
          if(RatingFields.includes(f)) {
            return r => (
              <EditorRating
                maxRating={editorBoard.boardSettings.editor_rating_max || 10}
                value={r[f]}
                digits={f === "editor_rating_avg" ? 1 : 0}
                onClick={() => data.review.startEditing(r)}
                />)
          }

          if(fileFields.includes(f)) {
            return r => (r as any)[f]
              ? <Buttons>
                  <GetMultifileDisplay
                    fileIds={(r as any)[f] as string || ""}
                    apiPathPrefix="/api/applications/document"
                    downloader={downloadFile}
                    />
                </Buttons>
              : <></>;
          }
          switch(f) {
            case "email":
              return (r,s,orig) => <PseudoLink2 border onClick={() => data.review.startEditing(r)}>{orig}</PseudoLink2>
          }
        }}
        tailAnchor={data.limitTrigger.pagingAnchor}
        rowButtons={r => data.comments.buttonForRecord(`${r._id}`)}
        />
    </Form>

    <ApplicationsConfigurationDialog
      data={data.config}
      title="Конфигурация"
      {...configDialog}
      />

    <ApplicationReviewDialog
      data={data.review}
      schema={data.schema}
      profileConfiguration={data.config.data}
      remove={data.remove.run}
      editorBoard={editorBoard}
      updateRating={data.updateRating}
      />

    {data.comments.popup}
  </>);
}
