import { useEffect, useMemo } from "react";
import { useUserSettings } from "../../toolympus/components/Settings/UserSettingsContext";
import { FieldType, Schema } from "../../toolympus/hooks/useSchema";
import { FieldDefinition } from "../../toolympus/components/schemed";
import { useUser } from "../../toolympus/userContext/UserContext";
import { useUserNames } from "./Users";

export interface EditorBoardConfiguration {
  editor_board?: Record<string, string>;
  editor_rating_max?: number;
}

const EmptyBoard: Record<string, string> = {};


export const useEditorBoardConfiguration = () => {
  const settings = useUserSettings();
  const { user } = useUser();
  const usernames = useUserNames();

  const boardSettings = settings.global as EditorBoardConfiguration || {};
  const board: EditorBoardConfiguration["editor_board"] = boardSettings.editor_board || EmptyBoard;

  const { editorRatingFields, myRatingKey, editorRatingFieldsDefinitionSimple, fullEditorsData } = useMemo(() => {
    const presentEditors = [1,2,3,4,5,6].filter(i => !!board[`editor_${i}`]);

    const fullEditorsData = presentEditors.map(i => ({
      i,
      editorKey: `editor_${i}`,
      ratingKey: `editor_rating_${i}`,
      isMe: board[`editor_${i}`] === (user?._id || "nothinghere"),
      name: usernames.getUsername(board[`editor_${i}`]),
    }))

    return {
      editorRatingFields: fullEditorsData.map(e => e.ratingKey),
      editorRatingFieldsDefinitionSimple: fullEditorsData.map(e => ([e.ratingKey, { label: `Р${e.i}${e.isMe ? " (мой)" : ""}`}])) as FieldDefinition[],
      myRatingKey: fullEditorsData.find(e => e.isMe)?.ratingKey,
      fullEditorsData,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board, user?._id, usernames.getUsername]);

  useEffect(() => {
    settings.ensureLoaded();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    boardSettings,
    board,
    editorRatingFields,
    myRatingKey,
    editorRatingFieldsDefinitionSimple,
    fullEditorsData,
  }
}

export type EditorBoardConfigurationData = ReturnType<typeof useEditorBoardConfiguration>;


export const EditorBoardSettingsSchema: Schema = {
  editor_rating_max: { type: FieldType.number, label: "Рейтинг max"}
}

export const useEditorBoardConfigurationEdit = () => {
  const settings = useUserSettings();

  const [editorBoardSchema, editorBoardFields] = useMemo(() => {
    const schema: Schema = {};
    [1,2,3,4,5,6].forEach(i => {
      schema[`editor_${i}`] = { label: `Редактор ${i}`, type: FieldType.dictionarySelect, dictionary: "Users" };
    });

    const fields: FieldDefinition[] = [1,2,3,4,5,6].map(i => [`editor_${i}`, { autoComplete: true }]);

    return [schema, fields];
  }, []);

  const boardSettings = settings.global as EditorBoardConfiguration || {};
  const board: EditorBoardConfiguration["editor_board"] = boardSettings.editor_board || {};

  useEffect(() => {
    settings.ensureLoaded();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    boardSettings,
    board,
    update: (c: Partial<EditorBoardConfiguration>) => settings.updateGlobal(c),
    updateBoard: (c: Record<string, string>) => settings.updateGlobal({ editor_board: { ...board, ...c }}),
    editorBoardSchema,
    editorBoardFields,
  }
}
