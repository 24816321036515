import { useEffect, useState } from "react";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { Issue, IssuesApiPath, IssueSchema, IssueSlot } from "./useIssuesList"
import { apiFetch } from "../../toolympus/api/core";



export const useEditIssueTemplate = () => {
  const data = useCrudItem<Issue>(`${IssuesApiPath}/template`, {
    defaultValue: { _id: -1, title: "", slots: [] },
  });

  const [slots, setSlots] = useState<IssueSlot[]>([]);
  const [slotsChanges, setSlotsChanges] = useState<Record<number, Partial<IssueSlot>>>({});
  useEffect(() => {
    if(data.data._id >= 0) {
      setSlots(data.data.slots);
    }
  }, [data.data]);


  useEffect(() => {
    const actualSlotsToUpdate = Object.keys(slotsChanges) as any as number[];
    const timeout = setTimeout(async () => {
      for (const sid of actualSlotsToUpdate) {
        const changes = slotsChanges[sid as any as number];
        await apiFetch<IssueSlot>(`${IssuesApiPath}/${data.data._id}/slot/${sid}`, "put", changes)
          .then(sUpdates => {
            setSlots(slots => slots.map(sx => sx.slot_id === sid ? { ...sx, ...sUpdates } : sx));
            setSlotsChanges(sx => {
              const sxc = { ...sx };
              delete sxc[sid];
              return sxc;
            })
          });
      }
    }, 1000);

    return () => clearTimeout(timeout);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsChanges]);

  const slotsX = {
    slots,
    addSlot: () => {
      const sdata: Partial<IssueSlot> = {
        title: "",
        issue_id: data.data._id,
        slot_id: Math.max(0, ...slots.map(s => s.sort_order)) + 1,
        sort_order: Math.max(0, ...slots.map(s => s.sort_order)) + 10,
      }
      apiFetch<IssueSlot>(`${IssuesApiPath}/${data.data._id}/slot`, "post", sdata)
        .then(s => setSlots(sx => {
          const withNew = [...sx, s];
          withNew.sort((a,b) => a.sort_order < b.sort_order ? -1 : 1);
          return withNew;
        }))
    },

    updateSlot: (id: number, changes: Partial<IssueSlot>) => {
      setSlots(sx => sx.map(s => s.slot_id === id ? { ...s, ...changes } : s));
      setSlotsChanges(x => ({ ...x, [id]: { ...(x[id] || {}), ...changes }}));
    },

    removeSlot: (id: number) => {

    },

    hasChanges: Object.keys(slotsChanges).length > 0,
  }

  return {
    ...data,
    schema: IssueSchema,
    slots: slotsX,
  }
  
}
