import React from 'react';
import { LoginForm } from '../../toolympus/components/auth';
import { useLoginForm } from '../../toolympus/hooks/auth';
import { useReturnUrl } from '../../toolympus/hooks/auth/useReturnUrl';
import { PublicPageWrapper } from '../Common/PublicPageWrapper';

export const LoginContainer = () => {
    const returnUrl = useReturnUrl();
    const signupData = useLoginForm("/api/login", returnUrl.fromParam || "/applications");
    return <PublicPageWrapper>
        <LoginForm loginData={signupData} />
    </PublicPageWrapper>;
}