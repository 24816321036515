import React from 'react';
import styled from '@emotion/styled';
import { IssueSlot, IssueSlotSchema } from './useIssuesList';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { Buttons, FormGrid } from '../../toolympus/components/primitives';
import { Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { PowerEditorBase } from '../../toolympus/components/PowerDoc';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 20px;  
`;

const SlotWrapper = styled.div`
  background: #f0f0f0;
  border-radius: 16px;
  box-shadow: 2px 4px 10px -5px #00000060;
  padding: 1rem;
`;


interface Props {
  slots: IssueSlot[];
  addSlot: () => void;
  updateSlot: (id: number, changes: Partial<IssueSlot>) => void;
}

export const SlotsConfigEditor = (props: Props) => {
  const { slots, addSlot, updateSlot } = props;
  
  return (
    <Wrapper>
      {slots.map(s => (
        <SlotWrapper key={s.slot_id}>
          <FormGrid noMargin columns="1fr">
            <FormControlsForFields
              fields={[
                ["title"],
              ]}
              data={s}
              onChange={(o,c) => updateSlot(s.slot_id, c)}
              schema={IssueSlotSchema}
              />

            <Typography variant="caption" color="textSecondary">Описание</Typography>
            <PowerEditorBase
              content={s.comment}
              update={v => updateSlot(s.slot_id, { comment: v})}
              />

          </FormGrid>
        </SlotWrapper>
      ))}

      <Buttons style={{ justifyContent: "center" }}>
        <Button size="small" color="primary" startIcon={<Add />} onClick={() => addSlot()}>
          добавить слот
        </Button>
      </Buttons>
    </Wrapper>
  );
}
