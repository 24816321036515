import React from 'react';
import { Form, FormGrid, LoadingIndicator, OccupyFreeSpace, SaveButton, useSaveable } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { useEditIssue } from './useEditIssue';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

interface Props {
  
}

export const IssueEditForm = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const data = useEditIssue(+id);

  useSaveable(data);

  return (
    <Form
      title="Номер"
      fitFullHeight
      headerItems={<>
        <OccupyFreeSpace />
        {data.isLoading && <LoadingIndicator sizeVariant="s" />}
        {data.hasChanges && <SaveButton action={() => data.save()} />}
        <Link to="/issues/config" style={{ textDecoration: "none", color: "unset" }}>
          <Button size="small" color="primary">конфигурация</Button>
        </Link>
      </>}>
      <FormGrid columns="2fr 1fr 1fr">
        <FormControlsForFields
          fields={[
            ["title"],
            ["created_at", { utcToLocal: true, disabled: true, }],
            ["created_by", { disabled: true, }],
          ]}
          data={data.data}
          schema={data.schema}
          onChange={(o,c) => data.update(c)}
          />
      </FormGrid>

      <FormGrid columns="2fr 1fr">
        <div style={{ background: "#f0f0f0"}}>
          здесь слоты
        </div>

        <div style={{ background: "#f0f0f0"}}>
          здесь то заявки, то чеклист
        </div>
      </FormGrid>
    </Form>
  );
}
