import React from 'react';
import { SimpleList2 } from '../../toolympus/components/primitives/SimpleList2';
import { useIssuesList } from './useIssuesList';
import { FormGrid, SimpleDialog } from '../../toolympus/components/primitives';
import { Link, useHistory } from 'react-router-dom';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { Button } from '@mui/material';

interface Props {
  
}

export const IssuesList = (props: Props) => {
  const data = useIssuesList();
  const history = useHistory();

  return (<>
    <SimpleList2
      data={{
        ...data,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => data.newRecord.startEditing(),
      }}
      fields={[
        ["title"],
        ["created_at", { utcToLocal: true }],
      ]}
      schema={data.schema}
      title="Номера"
      linkFields={[
        "title",
      ]}
      getLinkValue={r => `/issues/${r._id}`}
      headerActionsR={<>
        <Link to="/issues/config" style={{ textDecoration: "none", color: "unset" }}>
          <Button size="small" color="primary">конфигурация</Button>
        </Link>
      </>}
      />

    <SimpleDialog
      dialogTitle="Новый номер"
      noFullscreen
      isOpen={data.newRecord.isEditing}
      close={() => data.newRecord.cancel()}
      save={() => {
        data.newRecord.save().then(r => history.push(`/issues/${r._id}`));
      }}
      saveLabel="Создать">
      {!!data.newRecord.item &&
        <FormGrid columns="1fr">
          <FormControlsForFields
            fields={[
              ["title", { controlProps: { autoFocus: true }}]
            ]}
            data={data.newRecord.item}
            onChange={(o,c) => data.newRecord.update(c)}
            schema={data.schema}
            />
        </FormGrid>}
    </SimpleDialog>
  </>);
}
