import React from "react";
import { AssignmentTurnedInOutlined, InfoOutlined, NewspaperOutlined, SchoolOutlined, Settings } from "@mui/icons-material";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { DocumentationRoutes } from "./toolympus/components/Documentation";
import { ApplicationsList } from "./components/Applications";
import { PlCmsEditor } from "./components/Administration/PlEditor";
import { EditorBoardConfiguration } from "./components/Administration/EditorBoardConfiguration";
import { IssueConfigForm, IssueEditForm, IssuesList } from "./components/Issues";

export const Documentation = () => {
  return (
    <DocumentationRoutes
        apiPath="/api/documentation"
        rootSlug="root"
        defaultRootPageTitle="Документация"
        enableCustomFieldsBlock
        />
  );
}


const InnerRoutes: MenuRoute[] = [
    { 
        path: '/applications',
        title: 'Заявки',
        icon: <AssignmentTurnedInOutlined />,
        component: ApplicationsList,
        alsoActivateForPath: (path: string) => path.startsWith('/applications')
    },

    { 
      path: '/issues/config',
      title: 'Номер',
      hidden: true,
      component: IssueConfigForm,
    },
    { 
      path: '/issues/:id',
      title: 'Номер',
      hidden: true,
      component: IssueEditForm,
    },
    { 
        path: '/issues',
        title: 'Номера',
        icon: <NewspaperOutlined />,
        component: IssuesList,
        alsoActivateForPath: (path: string) => path.startsWith('/issues')
    },
    
    { 
        path: '/system',
        title: 'Конфигурация',
        icon: <Settings />,
        component: () => (
            <SystemSubmenu
                emails={{ apiPath: "/emails" }}
                users={{ allowInvites: true, allowPaswordReset: true, allowPaswordChange: true, }}
                messages={{}}
                apiTokens={{ apiPath: "/api/apitoken"}}
                cms={{ apiPath: "/api/cms", includeMediaLib: true, EditorComponent: PlCmsEditor }}
                queryConsole={{}}
                robud={{}}
                extraItems={[
                  {
                    key: "board",
                    label: "Редакция",
                    icon: <SchoolOutlined />,
                    items: [{ key: "board", label: "", component: EditorBoardConfiguration }]
                  },

                  {
                    key: "documentation",
                    label: "Документация",
                    icon: <InfoOutlined />,
                    items: [{ key: "documentation", label: "", component: Documentation }]
                  },
                ]}
            />),
    },
];

export default InnerRoutes;