import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { EditItemProps } from '../../toolympus/api/useNewItem';
import { Buttons, Dialog, FormGrid, LoadingIndicator, OccupyFreeSpace, useCopyText } from '../../toolympus/components/primitives';
import { FormControlsForFields, MaybeFieldDefinition } from '../../toolympus/components/schemed';
import { Schema } from '../../toolympus/hooks/useSchema';
import { IconButton, Typography } from '@mui/material';
import { DeleteOutlined, GetAppOutlined } from '@mui/icons-material';
import { useDownloadFile } from '../../toolympus/hooks/useDownloadFile';
import { FormControlBaseProps } from '../../toolympus/components/schemed/FormControlProps';
import { ApplicationConfig, ApplicationRecord } from './typings';
import { useApplicationControls } from './useApplicationsConfiguration';
import { GetMultifileDisplay } from './ApplicationsFiles';
import { Comments } from '../Administration/Comments';
import { EditorBoardConfigurationData } from '../Administration/useEditorBoardConfiguration';
import { EditorRating, RatingWrapper } from '../Administration/EditorRating';

const AvatarSizes = {
  large: 150,
  medium: 50,
  small: 24,
}

export const Avatar = styled.img<{ size?: "large" | "medium" | "small" }>`
  width: ${props => AvatarSizes[props.size || "large"]}px;
  height: ${props => AvatarSizes[props.size || "large"]}px;
  border-radius: ${props => AvatarSizes[props.size || "large"] / 2}px;
  box-shadow: 0 0 ${props => !props.size || props.size === "large" ? 10 : 8}px -5px ${props => props.theme.palette.grey[800]};
`;

const RatingsPanel = styled(FormGrid)`
  background-color: #efefef;
  border-radius: 8px;
  padding: 16px;
  box-shadow: inset 0 0 8px -5px ${props => props.theme.palette.grey[800]};

  & .title {
  }
  & .total {
  }

  & .name {
    padding-left: 1rem;
  }

  & > ${RatingWrapper}:not(:last-child) {
    & > ${OccupyFreeSpace} {
      height: 1rem;
      margin: 0 1rem 0 0.5rem;
      border-bottom: 1px dotted #00000040;
    }
  }

  & > ${RatingWrapper}:last-child {
    margin-top: 1rem;
  }
`;
RatingsPanel.defaultProps = { noMargin: true, columns: "1fr" };




interface Props {
  data: EditItemProps<ApplicationRecord>;
  remove: (item: ApplicationRecord) => Promise<any> | Promise<void>;
  schema: Schema;
  profileConfiguration: ApplicationConfig;
  editorBoard: EditorBoardConfigurationData;
  updateRating: (aId: string, ratingKey: string, rating: number) => Promise<any>;
}

interface DownloadFileProps {
  download: (fileId: string) => void;
  isDownloading: boolean;
  fileId: string | null | undefined;
  label: ReactNode;
}

export const DownloadFileControl = (props: DownloadFileProps) => {
  return <div>
    <Typography variant="caption" color="textSecondary" component="p">{props.label}</Typography>
    {!props.fileId
      ? <Typography>-</Typography>
      : props.isDownloading
        ? <LoadingIndicator sizeVariant="s" color="primary" />
        : <IconButton size="small" color="primary" onClick={() => props.download(props.fileId || "")}>
      <GetAppOutlined />
    </IconButton>}
  </div>
}

export const ApplicationReviewDialog = (props: Props) => {
  const { data, editorBoard } = props;
  const { schema: schemaFields, fields, fileFields, multifileFields } = useApplicationControls(props.profileConfiguration);
  const copyText = useCopyText();

  const downloadFile = useDownloadFile("");

  const fullFields: MaybeFieldDefinition[] = useMemo(() => {

    const FileControl = (p: FormControlBaseProps) => {
      return (
        <DownloadFileControl
          fileId={p.row[p.field]}
          download={fileId => !downloadFile.isLoading && downloadFile.download({ apiPath: `/api/applications/document/${fileId}` })}
          isDownloading={downloadFile.isLoading}
          label={(p.schema || {}).label}
          />
      )
    }

    const MultifileControl = (p: FormControlBaseProps) => {
      return <Buttons vertical>
        <Typography variant="caption" color="textSecondary">{p.schema?.label}</Typography>
        <Buttons>
          <GetMultifileDisplay
            apiPathPrefix="/api/applications/document"
            downloader={downloadFile}
            fileIds={p.row[p.field]}
            />
        </Buttons>
      </Buttons>
    }

    return fields.filter(fd => !!fd && fd[0] !== "email").map(f => {
      const [fid,options] = f || [];
      if(!!fid && fileFields.includes(fid)) {
        return [
          fid,
          {
            ...(options || {}),
            control: FileControl,
          }
        ];
      } else if(!!fid && multifileFields.includes(fid)) {
        return [
          fid,
          {
            ...(options || {}),
            control: MultifileControl,
          }
        ];
      } else {
        return f;
      }
    });
  }, [fields, fileFields, multifileFields, downloadFile]);

  const [queuedRatingUpdate, setQueuedRatingUpdate] = useState<{ ratingKey: string, value: number, recordId: string } | null>(null);

  useEffect(() => {
    if(queuedRatingUpdate) {
      const timeout = setTimeout(
        () => {
          props.updateRating(queuedRatingUpdate.recordId, queuedRatingUpdate.ratingKey, queuedRatingUpdate.value)
            .then(() => setQueuedRatingUpdate(null));
        },
        1000);
      return () => clearTimeout(timeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queuedRatingUpdate]);


  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle="Заявка"
      titleActions={<>
        <OccupyFreeSpace />
        <Comments entity="application" recordId={`${data.item?._id || "none"}`} />
        <IconButton size="small" onClick={() => data.item?._id && props.remove(data.item)}><DeleteOutlined /></IconButton>
      </>}>
      {!!data.item &&
        <FormGrid columns="1fr" noMargin>
          <RatingsPanel>
            <Typography className="title" color="textSecondary">Рейтинг</Typography>

            {editorBoard.fullEditorsData.map(e => (
              <EditorRating
                straighten
                value={queuedRatingUpdate?.ratingKey === e.ratingKey
                    ? queuedRatingUpdate.value
                    : (data.item as any)[e.ratingKey]}
                maxRating={editorBoard.boardSettings.editor_rating_max || 10}
                digits={0}
                label={<Typography component="span" className="name">{e.name}{e.isMe ? " (я)" : ""}</Typography>}
                update={e.isMe
                  ? v => data.item?._id && setQueuedRatingUpdate({ ratingKey: e.ratingKey, value: v, recordId: data.item?._id })
                  : undefined}
                />
            ))}

              <EditorRating
                straighten
                value={data.item.editor_rating_avg || 0}
                maxRating={editorBoard.boardSettings.editor_rating_max || 10}
                digits={1}
                label={<Typography className="total">Средний</Typography>}
                />

          </RatingsPanel>

          <FormGrid columns="1fr 200px">
            <FormControlsForFields
              schema={props.schema}
              data={data.item}
              onChange={() => {}}
              fields={[
                ["email", {
                  label: "E-mail",
                  readOnly: true,
                  controlProps: {
                    onClick: (e: any) => { e.stopPropagation(); e.preventDefault(); copyText(""); },
                  },
                }],
              ]}
              />

            <FormControlsForFields
              schema={props.schema}
              data={data.item}
              onChange={(o,c) => data.save(c)}
              fields={[
                ["approval_status", { label: "Статус", }],
              ]}
              />
            

          </FormGrid>
          

          <FormControlsForFields
            schema={schemaFields}
            readOnly
            data={data.item}
            onChange={() => {}}
            fields={fullFields}
            />
        </FormGrid>}
        
    </Dialog>
  );
}
