import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { Issue, IssuesApiPath, IssueSchema } from "./useIssuesList"

export const useEditIssue = (id: number) => {
  const data = useCrudItem<Issue>(`${IssuesApiPath}/${id}`, {
    defaultValue: { _id: -1, title: "", slots: [] },
    noLoad: !id || id < 0,
  })

  return {
    ...data,
    schema: IssueSchema,
  }
  
}
