import React from 'react';
import { useEditIssueTemplate } from './useEditIssueTemplate';
import { Form, LoadingIndicator, OccupyFreeSpace, SaveButton, useSaveable } from '../../toolympus/components/primitives';
import { SlotsConfigEditor } from './SlotsConfigEditor';

interface Props {

}

export const IssueConfigForm = (props: Props) => {
  const data = useEditIssueTemplate();

  useSaveable(data);

  return (
    <Form
      title="Конфигурация номера"
      fitFullHeight
      headerItems={<>
        <OccupyFreeSpace />
        {data.slots.hasChanges && <>H</>}
        {data.isLoading && <LoadingIndicator sizeVariant="s" />}
        {data.hasChanges && <SaveButton action={() => data.save()} />}
      </>}>
      
      <SlotsConfigEditor
        addSlot={data.slots.addSlot}
        slots={data.slots.slots}
        updateSlot={data.slots.updateSlot}
        />
    </Form>
  );
}
