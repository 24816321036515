import { useMemo } from "react";
import { useDictionaries } from "../../toolympus/hooks/useDictionaries"
import { toMap } from "../../toolympus/api/data";

export const useUserNames = () => {
  const usersDict = useDictionaries()["Users"];
  const [usernames,getUsername] = useMemo(() => {
    const usernames = toMap(usersDict.records, r => r.code, r => r.label);
    const getUsername = (id: string) => usernames[id] || id;
    return [usernames, getUsername];
  }, [usersDict.records])

  return {
    usernames,
    getUsername,
  }
}
